<script setup lang="ts">
import { OzBoxColors } from '@@/library/v4/components/OzBox.vue'

withDefaults(
  defineProps<{
    color?: OzBoxColors
    darkMode?: boolean | 'auto'
    pathClasses?: string[]
  }>(),
  {
    color: OzBoxColors.Primary,
    darkMode: 'auto',
    pathClasses: () => [],
  },
)
</script>

<script lang="ts">
export default {}
</script>

<template>
  <!-- For popover tip there is a bug with rendering SVG on webkit browsers (e.g. Safari), we need to add "transform scale-100" to fix it
    Reference: https://stackoverflow.com/questions/44170229/how-to-prevent-half-pixel-svg-shift-on-high-pixel-ratio-devices-retina -->
  <svg
    :class="[
      'scale-100',
      'pointer-events-none',
      'fill-current',
      'fixed',
      color === OzBoxColors.Primary && {
        'text-white-100': darkMode === false,
        'text-nightfall-900': darkMode === true,
        'text-white-100 dark:text-nightfall-900': darkMode === 'auto',
      },
      color === OzBoxColors.Secondary && {
        'text-light-ui-200': darkMode === false,
        'text-dark-ui-200': darkMode === true,
        'text-light-ui-200 dark:text-dark-ui-200': darkMode === 'auto',
      },
      color === OzBoxColors.Selection && {
        'text-grape-500': darkMode === false,
        'text-canary-500': darkMode === true,
        'text-grape-500 dark:text-canary-500': darkMode === 'auto',
      },
      color === OzBoxColors.Highlight && {
        'text-canary-0': darkMode === false,
        'text-nightfall-800': darkMode === true,
        'text-canary-0 dark:text-nightfall-800': darkMode === 'auto',
      },
      color === OzBoxColors.Destructive && {
        'text-tangerine-0': darkMode === false,
        'text-nightfall-800': darkMode === true,
        'text-tangerine-0 dark:text-nightfall-800': darkMode === 'auto',
      },
      color === OzBoxColors.Pink && {
        'text-padlet-pink-0': darkMode === false,
        'text-nightfall-800': darkMode === true,
        'text-padlet-pink-0 dark:text-nightfall-800': darkMode === 'auto',
      },
      color === OzBoxColors.PrimaryBlur && {
        'text-common-ui-l86': darkMode === false,
        'text-common-ui-d86': darkMode === true,
        'text-common-ui-l86 dark:text-common-ui-d86': darkMode === 'auto',
      },
    ]"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      :classes="color === OzBoxColors.PrimaryBlur && ['backdrop-filter', 'backdrop-blur-10']"
      d="M0 0h24-.652a4 4 0 0 0-2.788 1.147l-5.717 5.62a4 4 0 0 1-5.608 0l-5.718-5.62A4 4 0 0 0 .73 0H0z"
    />
  </svg>
</template>
