<script lang="ts">
import type { ResizeObserver } from '@@/bits/global'
import { observeSizeChange } from '@@/bits/resize_observer'
import OzBox, { OzBoxColors } from '@@/library/v4/components/OzBox.vue'
import OzOverlay from '@@/library/v4/components/OzOverlay.vue'
import OzPopoverTip from '@@/library/v4/components/OzPopoverTip.vue'
import type { PopoverAnchor, VueCssClass } from '@@/types'
import { debounce } from 'es-toolkit'
import { computed, nextTick, onBeforeUnmount, onMounted, ref } from 'vue'

export interface PopoverManualPosition {
  tipSide?: 'left' | 'right'
  boxTopOffset?: number
  boxLeftOffset?: number
}

export function getAnchorFromCoordinates(
  x: number,
  y: number,
  offset: { top: number; left: number } = { top: 0, left: 0 },
): Partial<PopoverAnchor> {
  return {
    top: y + offset.top,
    left: x + offset.left,
    width: 0,
    height: 0,
  }
}
export function getAnchorFromElement(
  el: Element,
  offset: { top: number; left: number } = { top: 0, left: 0 },
): Partial<PopoverAnchor> {
  const rect = el.getBoundingClientRect()
  return {
    top: rect.top + offset.top,
    left: rect.left + offset.left,
    width: rect.width,
    height: rect.height,
  }
}

export { OzBoxColors } from '@@/library/v4/components/OzBox.vue'
export default {}
</script>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    popoverAnchor: PopoverAnchor // Anchor location of the popover to determine where the popover is rendered
    xHeader?: boolean // Show or hide header box
    xScrim?: boolean // Show or hide scrim
    isSpectral?: boolean // See OzOverlay#isSpectral
    containerHeight?: number // Height of the container containing the popover and its scrim background, default to window height
    containerWidth?: number // Width of the container containing the popover and its scrim background, default to window width
    popoverWidth?: 'default' | 'wide' | number | 'auto' // Width of the popover, default to 224px. Wide is 320px. Auto will calculate the min width required on mount
    color?: OzBoxColors // Color scheme
    radius?: 0 | 8 | 12 | 16 | 20 | 24 // See OzBox#radius
    boxClasses?: VueCssClass // Extra classes for the box, for example to set a maximum height or add box shadow
    tipClasses?: VueCssClass // Extra classes for the tip, for example to set a custom color
    tipPathClasses?: string[] // Extra classes for the tip svg path, for example to add drop shadow
    darkMode?: true | false | 'auto' // Dark mode for color scheme
    dir?: 'ltr' | 'rtl' // Direction of the popover, either ltr or rtl
    positionMode?: 'smart' | 'manual' // Smart position mode will render the popover within the container/viewport. Manual position mode will render the popover according to manualPosition prop
    manualPosition?: PopoverManualPosition // Ignored in smart position mode.
    xPopoverTip?: boolean // Whether there is a popover tip, as that determines positioning of the popover modal
  }>(),
  {
    xHeader: true,
    xScrim: true,
    isSpectral: false,
    containerHeight: undefined,
    containerWidth: undefined,
    popoverWidth: 'default',
    color: OzBoxColors.Primary,
    radius: 20,
    boxClasses: () => [],
    tipClasses: () => [],
    tipPathClasses: () => [],
    darkMode: 'auto',
    dir: (document?.documentElement?.dir as 'ltr' | 'rtl') || 'ltr',
    positionMode: 'smart',
    manualPosition: () => ({}),
    xPopoverTip: true,
  },
)

const emit = defineEmits(['scrim-click', 'scrim-esc', 'trigger-anchor-update'])

const GAP_TO_ANCHOR = 2
const MIN_SCREEN_EDGE_PADDING = 12
const SIDE_POPOVER_TIP_DISTANCE_FROM_TOP = 20
const POPOVER_TIP_HEIGHT_SIDE = 24
const POPOVER_TIP_WIDTH_SIDE = 12
const POPOVER_TIP_HEIGHT_VERTICAL = 12
const POPOVER_TIP_WIDTH_VERTICAL = 24
const TOP_EDGE_MARGIN = 32
const popover = ref<InstanceType<typeof OzBox>>()

const anchorDistanceToBottom = computed(() => realContainerHeight.value - props.popoverAnchor.top)

const isRtl = computed(() => props.dir === 'rtl')

const autoCalculatedPopoverWidthPx = ref(0)

const popoverWidthPx = computed(() => {
  if (typeof props.popoverWidth === 'number') {
    return props.popoverWidth
  }

  if (props.popoverWidth === 'auto') {
    return autoCalculatedPopoverWidthPx.value
  }

  switch (props.popoverWidth) {
    case 'wide':
      return 320
    case 'default':
      return 224
    default:
      return 224
  }
})

const popoverHeightPx = ref(0)

const willExceedViewportLeftEdge = computed(() => {
  if (props.positionMode !== 'smart') return false
  return props.popoverAnchor.left - popoverWidthPx.value < 8
})

const popoverPosition = computed(() => props.popoverAnchor.position || 'top')

const willExceedViewportRightEdge = computed(() => {
  if (props.positionMode !== 'smart') return false
  if (popoverPosition.value === 'top' || popoverPosition.value === 'bottom') {
    return props.popoverAnchor.left + popoverWidthPx.value + 8 > realContainerWidth.value
  }
  return props.popoverAnchor.left + props.popoverAnchor.width + popoverWidthPx.value + 32 > realContainerWidth.value
})

const willExceedViewportBottomEdge = computed(() => {
  if (props.positionMode !== 'smart') return false
  if (popoverPosition.value === 'side') {
    return props.popoverAnchor.top + popoverHeightPx.value + MIN_SCREEN_EDGE_PADDING > realContainerHeight.value
  }
  return false
})

const willExceedViewportTopEdge = computed(
  () => props.popoverAnchor.top - popoverHeightPx.value - TOP_EDGE_MARGIN - POPOVER_TIP_HEIGHT_VERTICAL < 0,
)

const isAnchorTooHigh = computed(() => {
  if (props.positionMode !== 'smart') return false
  if (isAnchorTooLow.value) {
    return false
  }
  if (popoverPosition.value === 'top') {
    return willExceedViewportTopEdge.value
  }
  return false
})

const isAnchorTooLow = computed(() => {
  if (props.positionMode !== 'smart') return false
  if (popoverPosition.value === 'side') {
    return realContainerHeight.value - props.popoverAnchor.top - (props.popoverAnchor.height as number) < 24
  }
  if (popoverPosition.value === 'bottom') {
    return (
      realContainerHeight.value -
        props.popoverAnchor.top -
        (props.popoverAnchor.height as number) -
        popoverHeightPx.value <
      24
    )
  }
  return false
})

/**
 * Popover Styles
 */
const popoverSidePosition = computed(() => {
  if (props.positionMode === 'manual' && props.manualPosition?.tipSide) {
    return props.manualPosition.tipSide
  }
  if (popoverPosition.value === 'bottom') {
    return !isRtl.value
      ? willExceedViewportRightEdge.value
        ? 'left'
        : 'right'
      : willExceedViewportLeftEdge.value
      ? 'right'
      : 'left'
  }
  if ((!isRtl.value && willExceedViewportRightEdge.value) || (isRtl.value && !willExceedViewportLeftEdge.value))
    return 'left'
  return 'right'
})

const popoverOnTopStyle = computed(() => {
  if (isAnchorTooHigh.value) {
    return popoverOnSideStyle.value
  }
  const popoverLeft =
    popoverSidePosition.value === 'left'
      ? props.popoverAnchor.left + props.popoverAnchor.width / 2 - popoverWidthPx.value + 48
      : props.popoverAnchor.left + props.popoverAnchor.width / 2 - 48
  const manualLeftOffset = props.positionMode === 'manual' ? props.manualPosition?.boxLeftOffset || 0 : 0
  const styles = {
    bottom: `${anchorDistanceToBottom.value + POPOVER_TIP_HEIGHT_VERTICAL}px`,
    left: `${popoverLeft + manualLeftOffset}px`,
    width: props.popoverWidth === 'auto' ? 'auto' : `${popoverWidthPx.value}px`,
  }
  if (!willExceedViewportTopEdge.value) {
    return styles
  }
  const topValue = props.positionMode === 'manual' ? `${TOP_EDGE_MARGIN}px` : `${MIN_SCREEN_EDGE_PADDING}px`
  return { ...styles, top: topValue }
})

const popoverOnBottomStyle = computed(() => {
  if (isAnchorTooLow.value) {
    return popoverOnTopStyle.value
  }
  const popoverLeft =
    popoverSidePosition.value === 'right'
      ? props.popoverAnchor.left
      : props.popoverAnchor.left - popoverWidthPx.value + props.popoverAnchor.width
  const popoverTop =
    props.popoverAnchor.top +
    (props.popoverAnchor.height as number) +
    (props.xPopoverTip ? POPOVER_TIP_HEIGHT_VERTICAL : GAP_TO_ANCHOR)
  const manualLeftOffset = props.positionMode === 'manual' ? props.manualPosition?.boxLeftOffset || 0 : 0
  const manualTopOffset = props.positionMode === 'manual' ? props.manualPosition?.boxTopOffset || 0 : 0
  return {
    top: `${popoverTop + manualTopOffset}px`,
    left: `${popoverLeft + manualLeftOffset}px`,
    width: props.popoverWidth === 'auto' ? 'auto' : `${popoverWidthPx.value}px`,
  }
})

const popoverOnSideStyle = computed(() => {
  if (isAnchorTooLow.value) {
    return popoverOnTopStyle.value
  }
  const popoverLeftToLeft = props.xPopoverTip
    ? props.popoverAnchor.left - popoverWidthPx.value - POPOVER_TIP_WIDTH_SIDE
    : props.popoverAnchor.left - popoverWidthPx.value + 39
  const popoverLeftToRight = props.popoverAnchor.left + props.popoverAnchor.width + POPOVER_TIP_WIDTH_SIDE
  const popoverLeft = popoverSidePosition.value === 'left' ? popoverLeftToLeft : popoverLeftToRight
  const popoverTop = willExceedViewportBottomEdge.value
    ? Math.min(
        realContainerHeight.value - popoverHeightPx.value - MIN_SCREEN_EDGE_PADDING,
        props.popoverAnchor.top - SIDE_POPOVER_TIP_DISTANCE_FROM_TOP,
      )
    : props.popoverAnchor.top +
      (props.popoverAnchor.height as number) / 2 -
      (props.xPopoverTip ? POPOVER_TIP_HEIGHT_SIDE / 2 : 0) -
      SIDE_POPOVER_TIP_DISTANCE_FROM_TOP
  const manualTopOffset = props.positionMode === 'manual' ? props.manualPosition?.boxTopOffset || 0 : 0
  return {
    top: `${popoverTop + manualTopOffset}px`,
    left: `${popoverLeft}px`,
    minHeight: '60px', // So that the tip has space to not be weird
    width: props.popoverWidth === 'auto' ? 'auto' : `${popoverWidthPx.value}px`,
  }
})

const popoverStyle = computed(() => {
  if (popoverPosition.value === 'top') {
    return popoverOnTopStyle.value
  }
  if (popoverPosition.value === 'bottom') {
    return popoverOnBottomStyle.value
  }
  return popoverOnSideStyle.value
})

/**
 * Popover Tip Styles
 */

const popoverOnTopTipStyle = computed(() => {
  if (isAnchorTooHigh.value) {
    return popoverToSideTipStyle.value
  }
  return {
    bottom: `${anchorDistanceToBottom.value - POPOVER_TIP_HEIGHT_VERTICAL}px`,
    left: `${props.popoverAnchor.left + props.popoverAnchor.width / 2 - POPOVER_TIP_WIDTH_VERTICAL / 2}px`,
  }
})

const popoverToBottomTipStyle = computed(() => {
  if (isAnchorTooLow.value) {
    return popoverOnTopTipStyle.value
  }
  return {
    top: `${props.popoverAnchor.top + (props.popoverAnchor.height as number) - POPOVER_TIP_HEIGHT_VERTICAL}px`,
    left: `${props.popoverAnchor.left + props.popoverAnchor.width / 2 - POPOVER_TIP_WIDTH_VERTICAL / 2}px`,
    transform: 'rotate(180deg)',
  }
})

const popoverToSideTipStyle = computed(() => {
  if (isAnchorTooLow.value) {
    return popoverOnTopTipStyle.value
  }
  if (popoverSidePosition.value === 'left') {
    return {
      top: `${props.popoverAnchor.top + (props.popoverAnchor.height as number) / 2 - POPOVER_TIP_HEIGHT_SIDE / 2}px`,
      left: `${props.popoverAnchor.left - POPOVER_TIP_WIDTH_SIDE}px`,
      transform: 'rotate(270deg)',
    }
  }
  return {
    top: `${props.popoverAnchor.top + (props.popoverAnchor.height as number) / 2 - POPOVER_TIP_HEIGHT_SIDE / 2}px`,
    left: `${props.popoverAnchor.left + props.popoverAnchor.width - POPOVER_TIP_WIDTH_SIDE}px`,
    transform: 'rotate(90deg)',
  }
})

const popoverTipStyle = computed(() => {
  switch (popoverPosition.value) {
    case 'top':
      return popoverOnTopTipStyle.value
    case 'bottom':
      return popoverToBottomTipStyle.value
    default:
      return popoverToSideTipStyle.value
  }
})

const handleWindowResize = () => {
  realContainerHeight.value = props.containerHeight || window.innerHeight
  realContainerWidth.value = props.containerWidth || window.innerWidth
  emit('trigger-anchor-update')
}

const handleWindowScroll = () => {
  emit('trigger-anchor-update') // Tells the parent component to update the anchor due to DOM changes that should have dislocate the popover
}

const updatePopoverSize = () => {
  if (!popover.value) return
  popoverHeightPx.value = popover.value.$el.clientHeight
  autoCalculatedPopoverWidthPx.value = popover.value.$el.clientWidth
}

const realContainerHeight = ref(0)
const realContainerWidth = ref(0)

let resizeObserver: ResizeObserver | null

onMounted(() => {
  nextTick(() => {
    updatePopoverSize()
    if (props.positionMode === 'manual') return
    if (popover.value) {
      resizeObserver = observeSizeChange(popover.value.$el as HTMLElement, debounce(updatePopoverSize, 250))
    }
  })

  realContainerHeight.value = props.containerHeight || window.innerHeight
  realContainerWidth.value = props.containerWidth || window.innerWidth

  window.addEventListener('resize', handleWindowResize)
  window.addEventListener('scroll', handleWindowScroll)
})

onBeforeUnmount(() => {
  resizeObserver?.disconnect()
  window.removeEventListener('resize', handleWindowResize)
  window.removeEventListener('scroll', handleWindowScroll)
})
</script>

<template>
  <OzOverlay
    :dir="dir"
    :class="[
      // Can remove this once Oz page reset has been applied to all pages.
      'font-sans',
    ]"
    :scrim="xScrim ? 'popover' : null"
    :is-spectral="isSpectral"
    :should-fade-in="false"
    :dark-mode="darkMode"
    @scrim-click="emit('scrim-click')"
    @scrim-esc="emit('scrim-esc')"
  >
    <OzBox
      ref="popover"
      :color="color"
      :radius="radius"
      :class="['absolute', 'flex', 'flex-col', 'justify-center', 'overflow-hidden', boxClasses]"
      :style="popoverStyle"
      :dark-mode="darkMode"
    >
      <header v-if="xHeader && $slots.header">
        <slot name="header" />
      </header>

      <div class="flex grow flex-col overflow-hidden p-0 justify-center max-h-screen">
        <slot name="body" />
      </div>
    </OzBox>

    <OzPopoverTip
      v-if="xPopoverTip"
      :color="color"
      :class="tipClasses"
      :style="popoverTipStyle"
      :dark-mode="darkMode"
      :path-classes="tipPathClasses"
    />
  </OzOverlay>
</template>
